.square {
  display: flex;
  flex-direction: column;
  background-color: #83c3f7;
  border-radius: 15%;
  justify-content: center;
  align-items: center;

  box-shadow: 0px 0px 1px 1px green;
}

.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px green;
  }
  100% {
    box-shadow: 0 0 0 20px black;
  }
}

@font-face {
  font-family: "Control Freak";
  src:
    local("Control Freak"),
    url(../../assets/fonts/control-freak.regular.ttf) format("truetype");
}
